
@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZJhiI2B.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZthiI2B.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZNhiI2B.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZxhiI2B.woff2) format("woff2");
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZBhiI2B.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhiI2B.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hiA.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
    unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-ext-7Q6SVIPE.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.cyrillic-JSLPE6KW.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-ext-6OYGJJV7.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.greek-SHW2FPC4.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-ext-A5DUFOP6.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.latin-OW4UGSRU.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-ExtraBold.vietnamese-IBBC7NGV.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 800;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-ext-M4WHNGTS.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.cyrillic-JVU2PANX.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.greek-ext-4KCQBEIZ.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.greek-DPOQGN7L.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.latin-ext-J4DBSW7F.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.latin-Y3IVPL46.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Medium.vietnamese-PJV76O4P.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-ext-XOTVL7ZR.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.cyrillic-6LOMBC2V.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.greek-ext-WXWSJXLB.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.greek-YRST7ODZ.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.latin-ext-BASA5UL3.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.latin-UCM45LQF.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Bold.vietnamese-OEVJMXEP.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2);
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.cyrillic-ext-TU4ITVTR.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.cyrillic-JX7CGTYD.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.greek-ext-LS3GCBFI.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.greek-ZWCJHBP5.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.latin-ext-BZLEUMX6.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.latin-TETRYDF7.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Black.vietnamese-RXQCC3EJ.woff2);
    font-display: block;
    font-style: normal;
    font-weight: 900;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-ext-TRM4ITYR.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.cyrillic-FPHIQVZS.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-ext-JTGUUSP5.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.greek-LUNA3RFO.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-ext-KU7ICFYH.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.latin-FRVXWQSB.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BlackItalic.vietnamese-2Q7MQKJX.woff2);
    font-display: block;
    font-style: italic;
    font-weight: 900;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-ext-PEYDHC3S.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.cyrillic-7EIL6JWG.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-ext-3DJOYQMH.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.greek-TJBTLTT7.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-ext-FVPCPRBJ.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.latin-5ZFQS4XK.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-BoldItalic.vietnamese-W2625PGF.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-ext-YDGMJOJO.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.cyrillic-BFOVMAQB.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.greek-ext-4KOU3AHC.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+1F00-1FFF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.greek-OJTBJNE6.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0370-03FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.latin-ext-H4B22QN6.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.latin-2DWX32EN.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: Inter;
    src: url(https://app.framerstatic.com/Inter-Italic.vietnamese-TYMT6CKW.woff2);
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: Roboto Placeholder;
    src: local("Arial");
    ascent-override: 92.49%;
    descent-override: 24.34%;
    line-gap-override: 0%;
    size-adjust: 100.3%
}

@font-face {
    font-family: Inter Placeholder;
    src: local("Arial");
    ascent-override: 90%;
    descent-override: 22.43%;
    line-gap-override: 0%;
    size-adjust: 107.64%
}