.top-section {
    text-align: center;
    min-height: 100vh;
    background: #ffffff;
    align-items: center;
    justify-content: center;

    display: flex;
    flex: none;
    width: auto;
}

.top-items {
    /*margin: 4rem 8rem;*/
    width: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
}

.screenshot {
    animation: float 3s ease-in-out infinite;
}

.screenshot img {
    height: 40rem;
}

@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

.info {
    max-width: 70vh;
    min-width: 20vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.info h1 {
    font-size: 2.5em;
    color: #00796b;
}

.info p {
    font-size: 1.2em;
    color: #004d40;
}

.store-links {
    display: flex;
    flex-direction: row;
    gap: 10px
}

.store-links a {
    display: inline-block;
    padding: 10px 20px;
    background: #004d40;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.store-links a:hover {
    background: #00796b;
}

@media (max-width: 800px) {
    .top-items {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .info {
        text-align: center;
        justify-content: center;
    }

    .store-links {
        justify-content: center;
    }
}


.card {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    height: 10rem;
    width: 20rem;

    margin: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.card img {
    width: 4rem;
    height: 4rem;
}

.card-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
}

.card-text {
    font-size: 1rem;
    color: #8f8d8d;
}

.mid-high-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fafafa;
    padding: 40px 20px;
}

@media (max-width: 800px) {
    .top-items {
        flex-direction: column;
        justify-content: space-evenly;
    }

    .info {
        text-align: center;
        justify-content: center;
    }

    .store-links {
        justify-content: center;
    }

    .mid-high-section {
        flex-direction: column;
    }
}